import React from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'react-i18next';
import { map, size } from 'lodash';
import cx from 'classnames';
import { useNavigate } from 'react-router';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useModal } from 'hooks/useModal';
import { usePageTimer } from 'hooks/usePageTimer';
import {
  DEFAULT_SHEET_OF_FRAME46,
  FRAME_TO_PAYMENT_DIRECTLY,
  QUANTITY_DEFAULT,
} from 'constants/photo.const';
import { LOGGING_LEVEL } from 'constants/logging.const';
import { imageUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';
import { useLazyGetLayoutByFrameIdApiActionQuery } from 'store/api/layout.slice-api';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { emitSeqLog } from 'functions/seq-logging.func';
import { camerasApi } from 'store/api/camera.slice-api';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import './select-frame.css';

function SelectFrame() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { openErrorModal } = useModal();
  const { audio } = useSoundContext();
  const { isVerticalScreen } = useAppSelector((s) => s.booth);
  const { getNextPath, currentRoute } = useNewPhotoLifeCycleStep();
  const [getLayoutByFrameId] = useLazyGetLayoutByFrameIdApiActionQuery();
  const { listFramePhotos, listCameras } = useBoothAppContext();

  const { second } = usePageTimer({
    forwardFunc: () =>
      handleRedirectSelectLayout(listFramePhotos?.data?.[0]?.id)(),
  });

  // handle select frame
  const handleRedirectSelectLayout =
    (frameId: number | undefined) => async () => {
      if (!frameId) return;
      if (!size(listCameras?.data)) {
        dispatch(camerasApi.util.invalidateTags([{ type: 'Camera' }]));
        openErrorModal(t('common:noCameraFound'));
        emitSeqLog?.({
          level: LOGGING_LEVEL.FATAL,
          messageTemplate: `[SelectFrame] ${t('common:noCameraFound')}`,
        });
      } else if (isEqualVal(FRAME_TO_PAYMENT_DIRECTLY, frameId)) {
        audio?.playContinueClick?.();
        const response = await getLayoutByFrameId({
          frameId,
          numberOfPicture: QUANTITY_DEFAULT,
        });
        dispatch(
          setSelfeAppStateAction({
            frameId,
            quantityPicOfPerSheet: QUANTITY_DEFAULT,
            layoutId: response?.data?.data?.[0]?.id,
            quantitySheet:
              response?.data?.data?.[0]?.prices?.[0]?.numberOfPicture,
          }),
        );
        navigate(currentRoute?.directlyRoute || '');
      } else {
        audio?.playContinueClick?.();
        dispatch(
          setSelfeAppStateAction({
            frameId,
            quantityPicOfPerSheet: QUANTITY_DEFAULT,
            quantitySheet: DEFAULT_SHEET_OF_FRAME46,
          }),
        );
        navigate(getNextPath() || '');
      }
    };

  return (
    <>
      <div className="select-frame">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="select-frame__title page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={t(`${I18nNamespace.COMMON}:selectFrame`)}
              className="page-title"
            >
              {t(`${I18nNamespace.COMMON}:selectFrame`)}
            </Typography>
          </div>
        )}
        <div className="select-frame__btn-group">
          {map(listFramePhotos?.data, (item, ind) => (
            <div
              className={cx('dashed-border-select-frame select-frame__item', {
                'select-frame__item--right': !!ind,
              })}
            >
              <button
                key={item?.id}
                type="button"
                className="select-frame__btn"
                onClick={handleRedirectSelectLayout(item?.id)}
              >
                <Typography
                  variant={TYPOGRAPHY_VARIANTS.H2}
                  className="uppercase mb-16 text-center font-semibold"
                  color={TypographyColor.SECONDARY}
                >
                  {item?.name}
                </Typography>
                <img src={imageUrl(item?.image)} alt="" />
              </button>
            </div>
          ))}
        </div>
      </div>
      {isVerticalScreen && (
        <TitleAndLogo text={t(`${I18nNamespace.COMMON}:selectFrame`)} />
      )}
    </>
  );
}

export default SelectFrame;
