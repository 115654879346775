import React, { useEffect, useRef } from 'react';
import { pick } from 'lodash';
import cx from 'classnames';
import { SelfeRouteConst } from 'constants/route.const';
import CustomRoutes from 'components/custom-routes/CustomRoutes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import { useAppSelector } from 'store/store-hooks';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import Background from 'components/background/Background';
import PageActions from 'components/page-actions/PageActions';
import PopupConfirm from 'components/popup/PopupConfirm';
import Loader from 'components/loader/Loader';
import { BoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { APP_CONTENT_ID } from 'constants/dom-element.const';

import PhotoGuide from 'containers/selfe-booth/photo-guide/PhotoGuide';
import SelectFrame from 'containers/selfe-booth/select-frame/SelectFrame';
import Guide from 'containers/selfe-booth/guide/Guide';
import SelectLayout from 'containers/selfe-booth/select-layout/SelectLayout';

import Payment from 'containers/selfe-booth/payment/Payment';
import ShootingMode from 'containers/selfe-booth/shooting-mode/ShootingMode';
import ShootingPosition from 'containers/selfe-booth/shooting-position/ShootingPosition';
import Shooting from 'containers/selfe-booth/shooting/Shooting';
import PickPhoto from 'containers/selfe-booth/pick-photo/PickPhoto';
import AddFilter from 'containers/selfe-booth/add-filter/AddFilter';
import Print from 'containers/selfe-booth/print/Print';
import PhotoGuide2 from 'containers/selfe-booth/photo-guide/PhotoGuide2';
import StartPage from 'containers/selfe-booth/start-page/StartPage';
import GuideApi from 'containers/selfe-booth/guide/GuideApi';
import SelectTheme from 'containers/selfe-booth/select-theme/SelectTheme';
import OfflinePopup from 'containers/common/offline-popup/OfflinePopup';
import withModuleMaster from 'hoc/withModuleMaster';
import SelectSticker from '../selfe-booth/select-sticker/Select-sticker';
import './selfe-layout.css';

const router = [
  {
    path: SelfeRouteConst.HOME,
    element: <StartPage />,
  },
  {
    path: SelfeRouteConst.FRAME,
    element: <SelectFrame />,
  },
  {
    path: SelfeRouteConst.LAYOUT,
    element: <SelectLayout />,
  },
  {
    path: SelfeRouteConst.THEME,
    element: <SelectTheme />,
  },
  {
    path: SelfeRouteConst.STICKER,
    element: <SelectSticker />,
  },
  {
    path: SelfeRouteConst.PAYMENT,
    element: <Payment />,
  },
  {
    path: SelfeRouteConst.SHOOTING_POSITION,
    element: <ShootingPosition />,
  },
  {
    path: SelfeRouteConst.SHOOTING_MODE,
    element: <ShootingMode />,
  },
  {
    path: SelfeRouteConst.SHOOTING,
    element: <Shooting />,
  },
  {
    path: SelfeRouteConst.PICK_PHOTO,
    element: <PickPhoto />,
  },
  {
    path: SelfeRouteConst.ADD_FILTER,
    element: <AddFilter />,
  },
  {
    path: SelfeRouteConst.PRINT,
    element: <Print />,
  },
  {
    path: SelfeRouteConst.PHOTO_GUIDE,
    element: <PhotoGuide />,
  },
  {
    path: SelfeRouteConst.PHOTO_GUIDE_2,
    element: <PhotoGuide2 />,
  },
  {
    path: SelfeRouteConst.GUIDE,
    element: <Guide />,
  },
  {
    path: SelfeRouteConst.GUIDE_API,
    element: <GuideApi />,
  },
];

function SelfeLayout() {
  useTranslation([
    I18nNamespace.COMMON,
    I18nNamespace.PAGE,
    I18nNamespace.WARNING,
  ]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath, getPrevPath, currentRoute } = useNewPhotoLifeCycleStep();
  const { appLoading, modalConfirmState } = useAppSelector(
    (state) => state.selfeApp,
  );
  const { isVerticalScreen, loadingInList, loadingInitial } = useAppSelector(
    (state) => state.booth,
  );
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };
  const handleContinue = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      (videoRef.current as any).playbackRate = 1.4;
    }
  }, []);

  return (
    <BoothAppContext>
      <Loader
        id={APP_CONTENT_ID}
        className={cx(
          'no-drag select-none overflow-hidden w-screen h-screen flex justify-center app-content',
          {
            'app-vertical-screen': isVerticalScreen,
          },
        )}
        loading={appLoading || loadingInList || loadingInitial}
        outerSpinProps={{ className: 'app-content-pb-loader-icon-wrapper' }}
      >
        {isVerticalScreen && (
          <PageActions
            {...pick(currentRoute, [
              'hidePrevNext',
              'hidePrev',
              'hideNext',
              'PrevButtonProps',
              'NextButtonProps',
            ])}
            handleBack={handleBack}
            handleContinue={handleContinue}
            className="page-action-vertical"
          />
        )}
        <Background type={currentRoute?.backgroundType} />
        <CustomRoutes routes={router} />
        {!isVerticalScreen && (
          <PageActions
            {...pick(currentRoute, [
              'hidePrevNext',
              'hidePrev',
              'hideNext',
              'PrevButtonProps',
              'NextButtonProps',
            ])}
            handleBack={handleBack}
            handleContinue={handleContinue}
          />
        )}
        <PopupConfirm {...modalConfirmState} />
        <OfflinePopup />
      </Loader>
    </BoothAppContext>
  );
}

export default withModuleMaster(SelfeLayout);
