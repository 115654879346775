/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, HtmlHTMLAttributes, ReactElement, useEffect } from 'react';
import cx from 'classnames';
import { map, size } from 'lodash';
import { UseCarouselTypes, useCarousel } from 'hooks/useCarousel';
import { isEqualVal } from 'helpers/string.helper';
import Button from 'components/button/Button';
import { Icons } from 'assets/icon/Icons';
import './carousel.css';

export type Props = HtmlHTMLAttributes<HTMLDivElement> & {
  setCarousel?: (c: UseCarouselTypes) => void;
  slides: Array<number | string | ReactElement>;
  color?: string;
  interval?: number;
  hiddenDot?: boolean;
  hiddenBtn?: boolean;
};

export const Carousel: FC<Props> = ({
  setCarousel,
  slides,
  interval = 50000000,
  className,
  hiddenDot,
  hiddenBtn = true,
  ...rest
}: any): any => {
  const countSlide = size(slides);

  const carousel = useCarousel(countSlide, interval);

  const { active, setActive, handlers, style } = carousel;

  useEffect(() => {
    setCarousel?.({ ...carousel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    countSlide > 0 && (
      <div {...rest} className={cx('carousel', className)}>
        <div className="carousel-main-view">
          {!hiddenBtn && countSlide > 1 && (
            <Button
              className="carousel__btn-left"
              onClick={() =>
                setActive(active - 1 < 0 ? countSlide - 1 : active - 1)
              }
            >
              &lt;
            </Button>
          )}
          {!hiddenBtn && countSlide > 1 && (
            <Button
              className="carousel__btn-right"
              onClick={() =>
                setActive(active + 1 > countSlide ? 1 : active + 1)
              }
            >
              &gt;
            </Button>
          )}
          <div className="carousel-content" {...handlers} style={style}>
            <div
              className="carousel-item"
              onClick={() => setActive(size(slides) - 1)}
            >
              {slides[countSlide - 1]}
            </div>
            {map(slides, (slide, index: number) => (
              <div
                key={index}
                className={cx('carousel-item', {
                  'carousel-item-active': !!isEqualVal(active, index),
                })}
                onClick={() => !isEqualVal(active, index) && setActive(index)}
              >
                {slide}
              </div>
            ))}
            <div className="carousel-item" onClick={() => setActive(0)}>
              {slides[0]}
            </div>
          </div>
        </div>
        {!hiddenDot && (
          <ul className="carousel-indicators">
            {map(slides, (_, index: number) => (
              <button
                type="button"
                onClick={() => setActive(index)}
                key={index}
                className={`${isEqualVal(active, index) ? 'active' : ''}`}
              >
                {isEqualVal(active, index) ? (
                  <Icons.CarouselDotActive />
                ) : (
                  <Icons.CarouselDot />
                )}
              </button>
            ))}
          </ul>
        )}
      </div>
    )
  );
};
