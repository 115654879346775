/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import CachedImage from 'components/cached-image/CachedImage';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import { emitSeqLog } from 'functions/seq-logging.func';
import { resourceUrl } from 'helpers/url.helper';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { camerasApi } from 'store/api/camera.slice-api';
import {
  useGoToRobotArmHomePositionApiActionMutation,
  useLazyGetRobotArmStatusApiActionQuery,
} from 'store/api/robot-arm.slice-api';
import {
  resetSelfeAppStateAction,
  setSelfeAppStateAction,
} from 'store/features/app/selfeAppSlice';
import {
  resetTrackingStateAction,
  setTrackingStateAction,
} from 'store/features/app/trackingSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { v4 as uuidv4 } from 'uuid';
import { checkIsIdleArm } from '../shooting-position/shooting-position-helper';
import './start-page.css';

function StartPage() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const { audio } = useSoundContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getNextPath } = useNewPhotoLifeCycleStep();
  const { isPaidButNotShooting } = useAppSelector((s) => s.selfeApp);
  const { boothInfo, isRobotArm } = useAppSelector((state) => state.booth);
  const { listCapturePosition } = useBoothAppContext();

  // Robot Arm
  const [goToRobotArmHomePositionApi] =
    useGoToRobotArmHomePositionApiActionMutation();
  const [getRobotArmStatusApiAction] = useLazyGetRobotArmStatusApiActionQuery();

  const [isClickedOutsizeBtnStart, setIsClickedOutsizeBtnStart] =
    useState(false);

  const handleStart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const transactionId = uuidv4();
    audio?.playContinueClick?.();
    dispatch(setSelfeAppStateAction({ transactionId }));
    dispatch(setTrackingStateAction({ LastStartTimePurchase: new Date() }));
    navigate(getNextPath() || '');
    emitSeqLog?.({
      messageTemplate: `[StartPage] Start with transId: ${transactionId}`,
    });
  };

  const handleOutsideBtnStart = () => {
    setIsClickedOutsizeBtnStart(true);
    setTimeout(() => setIsClickedOutsizeBtnStart(false), 1000);
  };

  useEffect(() => {
    if (!isPaidButNotShooting) {
      dispatch(resetSelfeAppStateAction());
    }
    dispatch(resetTrackingStateAction());
    dispatch(camerasApi.util.invalidateTags([{ type: 'Camera' }]));
  }, [dispatch, isPaidButNotShooting]);

  useEffect(() => {
    if (isRobotArm && listCapturePosition?.data?.[0]) {
      const asyncUpdatePose = async () => {
        const statusRes = await getRobotArmStatusApiAction();
        if (checkIsIdleArm(statusRes?.data?.state)) {
          await goToRobotArmHomePositionApi();
        }
      };
      asyncUpdatePose();
    }
  }, [
    isRobotArm,
    getRobotArmStatusApiAction,
    goToRobotArmHomePositionApi,
    listCapturePosition?.data,
  ]);

  return (
    <>
      <div
        className="start-page-title-container"
        onClick={handleOutsideBtnStart}
      >
        <div
          className={cx('start-page-title-wrapper text-center', {
            'is-has-mascot': boothInfo?.StartMascotImage,
          })}
        >
          <button type="button" onClick={handleStart}>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              className={cx('start-page-title', {
                'text-stroke-swap': isClickedOutsizeBtnStart,
              })}
              data-text={t(`${I18nNamespace.COMMON}:touchToStart`)}
            >
              {t(`${I18nNamespace.COMMON}:touchToStart`)}
            </Typography>
          </button>
          {!!boothInfo?.StartMascotImage && (
            <div className="start-page-image-wrapper">
              <CachedImage
                imageUrl={resourceUrl(boothInfo?.StartMascotImage)}
                alt="macos"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default StartPage;
