import funLogoImage from 'assets/v2/images/white_logo.png';
import cx from 'classnames';
import { resourceUrl } from 'helpers/url.helper';
import React, { FC, HtmlHTMLAttributes, ReactElement } from 'react';
import { useAppSelector } from 'store/store-hooks';
import './loader.css';

export type LoaderProps = HtmlHTMLAttributes<HTMLDivElement> & {
  loading?: boolean;
  spin?: ReactElement | string;
  outerSpinProps?: HtmlHTMLAttributes<HTMLDivElement>;
};

const Loader: FC<LoaderProps> = ({
  children,
  loading = false,
  spin = null,
  outerSpinProps,
  ...props
}) => {
  const { boothInfo } = useAppSelector((s) => s.booth);

  return (
    <div
      {...props}
      className={cx('pb-loader', props?.className, {
        loading,
      })}
    >
      {children}
      {loading && (
        <div
          {...outerSpinProps}
          className={cx('pb-loader-icon-wrapper', outerSpinProps?.className)}
        >
          {spin || (
            <img
              src={
                boothInfo?.LogoImageUrl
                  ? resourceUrl(boothInfo?.LogoImageUrl)
                  : funLogoImage
              }
              className="pb-loader-img animate-scale-pulse mx-auto relative"
              alt="logo fun"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Loader;
