/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, HtmlHTMLAttributes } from 'react';
import { join, size } from 'lodash';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { FILTER_MATRIX, PhotoFilterTypes } from './photo-sheet-utils';
import './photo-sheet.css';

export type PhotoFilterProps = HtmlHTMLAttributes<HTMLDivElement> & {
  id?: number | string;
  imageAlt?: string;
  imageUrl?: string;
  filterValues?: number[];
  showPhotoIndex?: boolean;
  photoIndex?: number;
  rotate: string;
  flip: string;
  filterValue?:
    | PhotoFilterTypes
    | 'none'
    | 'black-white'
    | 'warm'
    | 'cool'
    | 'vintage'
    | 'gritty'
    | 'happy'
    | 'cinematic';
};

const PhotoFilter: FC<PhotoFilterProps> = ({
  id,
  imageUrl,
  imageAlt,
  filterValues = PhotoFilterTypes.NONE,
  showPhotoIndex = false,
  photoIndex,
  rotate,
  flip,
  ...rest
}) => (
  <div {...rest} className={`pb-photo-filter ${rest?.className}`}>
    <img
      alt={imageAlt}
      src={imageUrl}
      className="pb-photo-filter-image"
      style={{ transform: `rotate(${rotate}) scaleX(${flip})` }}
    />
    <svg
      className="pb-photo-filter-svg"
      style={{ transform: `rotate(${rotate}) scaleX(${flip})` }}
    >
      <filter id={`filter-image-${id}`} colorInterpolationFilters="sRGB">
        <feColorMatrix
          type="matrix"
          values={join(
            size(filterValues)
              ? filterValues
              : FILTER_MATRIX?.[PhotoFilterTypes.NONE],
          )}
        />
      </filter>
      <image
        filter={`url(#filter-image-${id})`}
        xlinkHref={imageUrl}
        x="0"
        y="0"
        width="100%"
        height="100%"
      />
    </svg>
    {!!showPhotoIndex && (
      <div className="pick-photo__img-order-no">
        <Typography
          className="font-black pick-photo__img-order-no-text"
          variant={TYPOGRAPHY_VARIANTS.XS}
        >
          {photoIndex}
        </Typography>
      </div>
    )}
  </div>
);

export default PhotoFilter;
