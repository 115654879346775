import React, { FC, HtmlHTMLAttributes } from 'react';
import cx from 'classnames';
import './background.css';

export enum BackgroundTypes {
  FRAME = 'frame',
  LAYOUT = 'layout',
  PAYMENT = 'payment',
}

export type Props = HtmlHTMLAttributes<HTMLDivElement> & {
  type?: BackgroundTypes;
};

const Background: FC<Props> = ({
  className,
  type = BackgroundTypes.FRAME,
  ...rest
}) => (
  <div
    {...rest}
    className={cx(
      'w-full h-full absolute top-0 left-0 -z-50 object-cover bg-image',
      {
        'bg-image-frame': type === BackgroundTypes.FRAME || !type,
        'bg-image-layout': type === BackgroundTypes.LAYOUT,
        'bg-image-payment': type === BackgroundTypes.PAYMENT,
      },
      className,
    )}
    style={{
      ...rest?.style,
      // @ts-ignore
    }}
  />
);

export default Background;
